import '../styles/home.css'
import '../styles/about.css'

export function AboutPage(props){
    return(
        <div className="Home" id="aboutId">
            <div className="aboutText">
                <h1 className="Heading1">About</h1>
                <p>Far North QLD Piano Services is owned and operated by myself - Nick Springall. </p>

                <p>In 2011 I studied at the Australasian School of Piano Technology under Brent Ottley, one of our country's most highly regarded Concert Technicians. This nationally accredited and Yamaha sponsored training provided me with an extremely high standard of knowledge and skill.
                    After completing this training, I began working as a Piano Technician for Melbourne Piano Sales, preparing and servicing new Kawai pianos alongside Yamaha, Steinway and other makes. </p>

                <p>Along with this work, I specialised in piano restorations, running Melbourne Piano Restorations workshop and working for Melbourne's premier restoration shops. Offering stringing, action replacement and replacement of structural parts to the trade. </p>

                <p>I had the privilege of studying fine furniture making at l'Ecole Superiere d'Ebenisterie d'Avignon in 2019 and enjoy fine woodworking.
                    I moved part time to North QLD in 2020, continuing to work in Melbourne for Kawai and Melbourne Piano Sales before moving permanently to Lake Barrine on the beautiful Atherton Tablelands in 2023. </p>

                <p>I'm fully Yamaha and Kawai accredited and an Australian Registered Piano Technician. I look forward to working for you and looking after your piano needs.</p>
            </div>
            <div className="aboutImage">
                    <img className="aboutImageFile"src="images/about/371332379_1271074730442263_2710328241186994236_n.jpg"/>
            </div>
        </div>
    )
}