import { Helmet } from 'react-helmet-async'
import '../styles/home.css'
import '../styles/header.css'

export function HomePage(props){


    return(

            <div className="Container">
                <Helmet>
                    <title>FNQ Piano Services</title>
                    <meta name='FNQ Piano Services' content='Providing high quality maintenance, repair and restoration services across far North QLD and beyond.' />
                </Helmet>

                <div className="Home" id="homeId">
                        <h1>Providing high quality maintenance, repair and restoration services across Far North QLD and beyond.</h1>
                </div>
            </div>
    )
}