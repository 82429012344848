import '../styles/header.css'


const imagearray = ["../images/header_scroll/IMG_0701_low.jpg", "../images/header_scroll/IMG_6543_low.jpg", "../images/header_scroll/IMG_6610 2_low.jpg"]


export function Header() {

    const image = "images/logo_img.jpg"

    return(
        <div className="Header">
            <div className = "Logo">
                <img className="Image" src={image} />
            </div>
        </div>
    )
}