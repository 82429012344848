import logo from './logo.svg';
import './App.css';

import { useEffect } from 'react';

import { Header } from './components/homeheader';
import { NavBar } from './components/navbar'
import { AboutPage } from './pages/about';
import { ContactPage } from './pages/contact';
import { HomePage } from './pages/home';
import { RestorationPage } from './pages/restoration';
import { ServiceAreasPage } from './pages/service_areas';
import { ServicesPage } from './pages/services';
import { Background } from './components/background';

import { HelmetProvider } from 'react-helmet-async';

import './styles/app.css'

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-H4DYSGCK9S"
ReactGA.initialize(TRACKING_ID)

function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "FNQ Piano Services" })
  }, []);
  
  return (
    <HelmetProvider>
   
      <div className="Wrapper">
      
          <Background />

        <div className="Content">
          <Header/>
          <HomePage/>
          <NavBar/>
          <AboutPage/>
          <ServicesPage/>
          <RestorationPage/>
          <ServiceAreasPage/>
          <ContactPage/>
        
      </div>
    </div>
    </HelmetProvider>
  );
}

export default App;
