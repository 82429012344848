import { serviceList } from "../pages/services"
import "../styles/services.css"

export default function ServicesRender(serviceElement){

    const ServiceElement = (element) => {

        const title = element
        const content = serviceList[element]
        const text = content[0]
        const image = content[1]

        return (
            <div className="Service">
                <div className="ServiceContent">
                    <h3>{title}</h3>
                    {text}
                </div>
                <div className="ServicesImgDiv">
                    <img className="ServiceImg" src={image} />
                </div>
            </div>
        )
    } 


    let ServicesElements = []

    for (const element in serviceList) {
       ServicesElements.push(ServiceElement(element))
    }


    return (ServicesElements.map((service) => <li className="ServiceListItem">{service}</li>)
    )
}