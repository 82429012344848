"use client";

import '../styles/servicearea.css';
import { useState } from "react";
import {
    APIProvider,
    Map,
    Marker,
    Pin,
    InfoWindow,
    useMapsLibrary
} from "@vis.gl/react-google-maps"


export function ServiceAreaMap() {
    const fnqpos = { lat:-17.978158, lng:138.577306}

    return ( 
        <APIProvider apiKey={process.env.REACT_APP_MAP_API_KEY}>
            <div className="MapStyle">
                <Map zoom= {5.5} center={fnqpos}>
                    <Marker position={{lat:-16.928420, lng:145.768425}} />
                    <Marker position={{lat:-19.256940, lng:146.807579}} />
                    <Marker position={{lat:-19.571595, lng:147.406538}} />
                    <Marker position={{lat:-20.283963, lng:148.725630}} />
                    <Marker position={{lat:-21.148101, lng:149.188327}} />
                    <Marker position={{lat:-20.078973, lng:146.261906}} />
                    <Marker position={{lat:-20.704091, lng:140.506932}} />
                    <Marker position={{lat:-20.724522, lng:139.494835}} />
                    <Marker position={{lat:-23.702633, lng:133.876094}} />
                    <Marker position={{lat:-14.464793, lng:132.262664}} />
                    <Marker position={{lat:-12.445519, lng:130.840405}} />
                    
                </Map>
            </div>
        </APIProvider>
    )
}