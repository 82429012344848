import { ServiceAreaMap } from '../components/map';
import '../styles/servicearea.css';


export function ServiceAreasPage(props){
    return(
        <div className='serviceAreas' id='serviceAreasId'>
            <h1>Service Areas</h1>
            <div className='MapContainer'>
                <ServiceAreaMap className="Map"/>
            </div>
        </div>
    )
}