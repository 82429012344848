import { useState } from 'react'
import { Link } from 'react-scroll'
import '../styles/nav.css'

export function NavBar(props) {

    

    return(
        <nav className='Nav'>
            <ul className="NavList">
                <li className='NavListItem'>
                    <Link className="Link"
                    activeClass="active"
                    to="homeId"
                    spy={true}
                    smooth={true}
                    offset={-400}
                    duration={500}>
                        Home
                    </Link>
                </li>
                <li className='NavListItem'>
                <Link className="Link"
                    activeClass="active"
                    to="aboutId"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>
                        About
                    </Link>
                </li>
                <li className='NavListItem'>
                <Link className="Link"
                    activeClass="active"
                    to="servicesId"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>
                        Services
                    </Link>
                </li>
                <li className='NavListItem'>
                <Link className="Link"
                    activeClass="active"
                    to="restorationId"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>
                        Restoration
                    </Link>
                </li>
                <li className='NavListItem'>
                <Link className="Link"
                    activeClass="active"
                    to="serviceAreasId"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>
                        Service Areas
                    </Link>
                </li>
                <li className='NavListItem'>
                <Link className="Link"
                    activeClass="active"
                    to="contactId"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>
                        Contact
                    </Link>
                </li>
            </ul>  
        </nav>
    )
}