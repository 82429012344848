
import ServicesRender from "../components/servicesrendering";

import "../styles/services.css";


const image = "../logo512.png" 

export const serviceList = {
    Tuning: [<p>Each service will address your piano's tuning. Tuning inevitably goes out over time due to humidity and temperature variations and also by simply being played. Regular tuning ensures that the piano doesn't drift too far out of tune, leaving a stable tuning that lasts longer. Instruments that have not been serviced for a while will typically require several tunings within the one service to achieve a stable tuning at concert pitch. I'm trained to tune your piano by ear and use the service time as efficiently as possible to get your instrument into shape.</p>, "images/services/tuning_img.jpg"],
    Regulation: ["Equally as important to your pianos performance, the action needs to be regularly serviced to have it playing as smoothly and dynamically as possible. This is achieved by adjusting the many hundreds of parts to the mechanism as they change over time, continually working to maintain them at their factory specifications. A piano action that is out of regulation will be inconsistent and difficult to play, lacking dynamics and control. By contrast, a finely regulated instrument will have a silky touch that is effortless to play.", "images/services/Regulation_img.jpg"],
    Voicing: ["As a piano is played overtime and the hammers hit the strings thousands of times, the felt surface becomes worn and compacted. This leads a to harsh, one dimensional and inconsistent tone. Voicing is the art of restoring the hammer's tone to a pleasing, balanced and musical tone. Hammers are voiced by filing, shaping and needling the surface along with strategically adding material to the hammer felt to bring back dull and lifeless hammers.", image],
    Repairs: ["some repair content here", image],
    Inspections: ["Some inspection content here", image],
    Institutions: ["Some institution content here", "images/services/institutionscrop_img.jpg"]
}


export function ServicesPage(props){


    
    return(
        <div className="Services" id="servicesId">
            <div className="ServicesHeader">
                <h1 className="Heading1">Services</h1>
                <p>Whether you have just begun your musical journey or are an accomplished pianist, regular servicing every 6 to 12 months will ensure your piano functions and sounds at its best. Every service addresses tuning, regulation and voicing. This holistic approach creates a stable piano that is a joy to play.</p>
            </div>
            <div className="ServicesRender">
                <ServicesRender />
            </div>
        </div>
    )
}
